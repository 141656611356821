.app-logo {
  max-width: 100%;
  height: auto;
}

/* Media query for mobile screens */
@media screen and (max-width: 767px) {
  .app-logo {
    max-width: 80%; /* Adjust this value to change the logo size on mobile */
  }
}

/* Styles for the Navbar toggler (hamburger menu and close icon) */
.navbar-toggler {
  border: none;
}

.navbar-toggler-icon {
  background-image: none;
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.navbar-toggler .navbar-toggler-icon-inner {
  width: 30px;
  height: 2px;
  background-color: #000; /* Change this to match your site's color theme */
}

.navbar-toggler .navbar-toggler-icon-inner::before,
.navbar-toggler .navbar-toggler-icon-inner::after {
  content: "";
  display: block;
  width: 30px;
  height: 2px;
  background-color: inherit;
}

.navbar-toggler .navbar-toggler-icon-inner::before {
  transform: translateY(-7px);
}

.navbar-toggler .navbar-toggler-icon-inner::after {
  transform: translateY(7px);
}

.navbar-toggler.collapsed .navbar-toggler-icon-inner::before {
  transform: translateY(0) rotate(45deg);
}

.navbar-toggler.collapsed .navbar-toggler-icon-inner::after {
  transform: translateY(0) rotate(-45deg);
}

.mobile-menu-wrapper {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  overflow-y: auto;
}

.mobile-menu-wrapper.mobile-menu-visible {
  display: block;
}

.mobile-menu-close {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
}

.mobile-menu {
  background-color: #fff;
  width: 80%;
  max-width: 300px;
  padding: 15px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
}

.mobile-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.mobile-menu li {
  border-bottom: 1px solid #eee;
}

.mobile-menu li:last-child {
  border-bottom: none;
}

.mobile-menu a {
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  color: #333;
}

.mobile-menu a:hover {
  background-color: #f5f5f5;
}
