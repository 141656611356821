.header-labels {
  display: flex;
  justify-content: space-between;
}

.lbl-job-expired {
  position: absolute;
  background-color: red;
  color: white;
  padding: 2px 3px;
  border-radius: 3px;
  font-size: 12px;
  left: 100px;
  top: auto;
}
